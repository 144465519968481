import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as CheckIco } from '../../assets/check.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../assets/magnifying-glass.svg';
import StationStatusSummaryPanel from '../../components-v2/business-components/station-status-summary-panel/station-status-summary-panel';
import Box, { ChartSkeleton, HugeListItemSkeleton, StackedSkeleton } from '../../components-v2/utils';
import IconExplanationSection from '../../components/icon-explanation-section';
import { ChargePointFilterParameters } from '../../components/infrastructure/filter-area-parameters.dto';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { getPlugTypeOptions } from '../../components/outlet-type-icon';
import { ApiBasedContent, PageLayout } from '../../components/page-layout';
import Paging from '../../components/paging';

import ChargepointListItem from '../../components-v2/business-components/chargepoint-list-item/chargepoint-list-item';
import { CHARGEPOINT_DOMAIN_STATUS_COLORS } from '../../components-v2/business-components/domain-mappings';
import { ButtonSegment, ButtonSegments, ButtonToggle, ButtonToggles } from '../../components-v2/button';
import FilterToggle from '../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../components-v2/input';
import SingleSelect from '../../components-v2/select/single-select';
import TabPanel, { Tab, TabContent, Tabs } from '../../components-v2/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargePointItemDto } from '../../services/api-client/csp-api';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { hierarchyValidator, pagingValidator } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import { CreateServiceTicketAction } from '../support/create-ticket.page';
import ChargePointRemoteActions, { useChargePointRemoteActions } from './chargepoint-remote-actions';
import styles from './chargepoints-list-page.module.scss';
import StationCustomNamePopup from './custom-name-popup';

type FilterParameters = {
  skip?: number | null;
  limit?: number | null;
  clientCode?: string;
  nodes?: string[];
} & ChargePointFilterParameters;
const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

export default function ChargepointsListPage() {
  const { _t } = useBetterTranslate('chargepoints-list-page');

  const navigate = useBetterNavigate();
  const { pathname } = useLocation();

  const validators = { ...pagingValidator, ...hierarchyValidator };
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, validators);

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const toggleFilter = (key: keyof FilterParameters) => {
    return () => {
      setFilterParams({ [key]: filterParams[key] ? undefined : '1' });
    };
  };
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const setNewFilterParams = (filter: FilterParameters) => {
    // Set all filter params to undefined except the passed ones
    let property: keyof typeof filterParams;
    const undefinedFilterParams: FilterParameters = {};
    for (property in filterParams) {
      undefinedFilterParams[property] = undefined;
    }
    _setInnerFilterParams({
      ...undefinedFilterParams,
      ...filter,
    });
  };
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [customNameChangeChargePoint, setCustomNameChangeChargePoint] = useState<ChargePointItemDto | undefined>(undefined);
  let customNamePopupTitle = _t('Edit station custom name');
  if (customNameChangeChargePoint && customNameChangeChargePoint?.customName === customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Create chargepoint custom name');
  } else if (customNameChangeChargePoint && customNameChangeChargePoint?.customName !== customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Edit chargepoint custom name');
  }

  const remoteActions = useChargePointRemoteActions({
    refreshRequested: () => chargePointsRefresh(),
    remoteStop: {
      do: async (cp) => (await api.station.remoteStopSession({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteStart: {
      do: async (cp, card) => (await api.station.remoteStartSession({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteReserve: {
      do: async (cp, card, minutes) =>
        (await api.station.remoteReserve({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, minutes: minutes })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteCancelReserve: {
      do: async (cp) => (await api.station.remoteReserveCancel({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteUnlock: {
      do: async (cp) => (await api.station.remoteUnlock({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteChangeAvailability: {
      do: async (cp, av) => (await api.station.remoteChangeChargePointAvailability({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, type: av })).data,
    },
  });

  const [chargePointsResp, chargePointsFetching, chargePointsErr, chargePointsRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        plugTypes?: string[],
        ac?: string,
        dc?: string,
        available?: string,
        charging?: string,
        occupied?: string,
        failure?: string,
        unavailable?: string,
        unknown?: string,
        query?: string,
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;

        const res = await api.station.listChargePoints({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
          outletTypes:
            plugTypes?.filter((type) =>
              getPlugTypeOptions()
                .map((option) => option.name)
                .includes(type)
            ) || [],
          ac: ac === '1',
          dc: dc === '1',
          available: available === '1',
          charging: charging === '1',
          occupied: occupied === '1',
          failure: failure === '1',
          unavailable: unavailable === '1',
          unknown: unknown === '1',
          searchText: query || undefined,
          hideNotInOperation: true,
          isPublic: isPublic === '1' ? true : undefined,
        });
        setHasExternalHardware(!!res.data.chargePoints.find((s) => s.isExternalHardware));
        setHasPublicStations(!!res.data.chargePoints.find((s) => s.isPublic));
        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.plugTypes,
    filterParams.ac,
    filterParams.dc,
    filterParams.available,
    filterParams.charging,
    filterParams.occupied,
    filterParams.failure,
    filterParams.unavailable,
    filterParams.unknown,
    filterParams.search,
    filterParams.public
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToStationListPage = (filter: { online?: number; offline?: number; failure?: number; notInOperation?: number; nodes?: string[] }) => {
    const link = `/infrastructure/stations/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[]) => {
        if (!clientCode) return undefined;

        return await api.station.getSummary({
          code: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
        });
      },
      map: (data) => {
        if (!data) return data;

        const stationsTotal = data.stationsOnline + data.stationsOffline + data.stationsFailure + data.stationsNotInOperation;
        const connectorsTotal =
          data.connectorsAvailable + data.connectorsCharging + data.connectorsUnavailable + data.connectorsUnknown + data.connectorsFailure + data.connectorsOccupied;
        return {
          clientContext: data.clientContext,
          totalEnergyConsumption: data.stationsAssumedEnergy,
          stationsSeries: {
            online: data.stationsOnline,
            offline: data.stationsOffline,
            failures: data.stationsFailure,
            notInOperation: data.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.connectorsAvailable,
            charging: data.connectorsCharging,
            unavailable: data.connectorsUnavailable,
            occupied: data.connectorsOccupied,
            unknown: data.connectorsUnknown,
            failure: data.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },
    filterParams.clientCode,
    filterParams.nodes
  );

  return (
    <PageLayout className={styles.root}>
      <>
        <OrgHierarchyPicker
          selectMode='node'
          clientContext={mainModel?.clientContext}
          onNodeSelected={(clientCode, selectedCodes) => {
            if (clientCode !== mainModel?.clientContext?.code) {
              navigate(`/infrastructure/chargepoints/${clientCode}`, {
                nodes: selectedCodes,
              });
            } else {
              setFilterParams({ nodes: selectedCodes });
            }
          }}
          {...createClientSearchProps(mainModel?.clientContext)}
          selectedNodes={filterParams.nodes}
        />
        {/* Context popups */}
        <ChargePointRemoteActions {...remoteActions.chargePointRemoteActionsProps} />

        <ApiBasedContent
          resp={mainModel}
          err={errMainModel}
          fetching={fetchingMainModel}
          placeholder={() => (
            <Box kind='hflex' gap='m'>
              <ChartSkeleton />
              <ChartSkeleton />
            </Box>
          )}
        >
          {(mainModel) => {
            return (
              <Box kind={'vflex'} gap='xl'>
                <Box className={styles.charts} kind='vflex' gap='m'>
                  <StationStatusSummaryPanel
                    connectors={{
                      overallTotal: mainModel.connectorsSeries.total,
                      total: mainModel.connectorsSeries.total,
                      available: mainModel.connectorsSeries.available,
                      charging: mainModel.connectorsSeries.charging,
                      failure: mainModel.connectorsSeries.failure,
                      occupied: mainModel.connectorsSeries.occupied,
                      unavailable: mainModel.connectorsSeries.unavailable,
                      unknown: mainModel.connectorsSeries.unknown,
                      onAvailableClicked: () => setNewFilterParams({ available: '1', nodes: filterParams.nodes }),
                      onChargingClicked: () => setNewFilterParams({ charging: '1', nodes: filterParams.nodes }),
                      onOccupiedClicked: () => setNewFilterParams({ occupied: '1', nodes: filterParams.nodes }),
                      onUnavailableClicked: () => setNewFilterParams({ unavailable: '1', nodes: filterParams.nodes }),
                      onUnknownClicked: () => setNewFilterParams({ unknown: '1', nodes: filterParams.nodes }),
                      onFailureClicked: () => setNewFilterParams({ failure: '1', nodes: filterParams.nodes }),
                    }}
                    stations={{
                      overallTotal: mainModel.stationsSeries.total,
                      total: mainModel.stationsSeries.total,
                      failures: mainModel.stationsSeries.failures,
                      offline: mainModel.stationsSeries.offline,
                      online: mainModel.stationsSeries.online,
                      notInOperation: mainModel.stationsSeries.notInOperation,
                      onOnlineClicked: () => navigateToStationListPage({ online: 1, nodes: filterParams.nodes }),
                      onOfflineClicked: () => navigateToStationListPage({ offline: 1, nodes: filterParams.nodes }),
                      onFailureClicked: () => navigateToStationListPage({ failure: 1, nodes: filterParams.nodes }),
                      onNotInOperationClicked: () => navigateToStationListPage({ notInOperation: 1, nodes: filterParams.nodes }),
                    }}
                  />
                  <div data-cy='totalEnergyConsumption' className={styles.totalEnergyConsumption}>
                    {_t('Aktuelle Ladeleistung')}: {formatter.formatNumber(Math.round(mainModel.totalEnergyConsumption))} kW
                  </div>
                </Box>

                {(hasExternalHardware || hasPublicStations) && <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />}
              </Box>
            );
          }}
        </ApiBasedContent>

        <TabPanel>
          <Tabs>
            <Tab fillIco dataCy='tab-station-list' txt={_t('Ladestationen')} onClick={() => navigateToStationListPage({ nodes: filterParams.nodes })} ico={<StationIco />}></Tab>
            <Tab fillIco dataCy='tab-chargepoint-list' active={true} txt={_t('Ladepunkte')} ico={<CheckIco />}></Tab>
          </Tabs>
          <TabContent active={true}>
            <Box kind={'vflex'} gap='m'>
              <Box kind={'hflex'} align='center' gap='m'>
                <FormContainerV2>
                  <InputV2
                    placeholder={_t('Search')}
                    icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                    value={searchTerm || ''}
                    onChange={updateSearchTerm}
                    type='text'
                  />
                </FormContainerV2>
                <SingleSelect<{ name: string; id: string; title: string }>
                  placeholder={(filterParams.plugTypes || []).length <= 0 ? _t('Steckertyp') : filterParams.plugTypes?.join(', ')}
                  options={getPlugTypeOptions()}
                  classNames={styles.plugSelect}
                  createOption={(opt) => {
                    const isChecked = (filterParams.plugTypes || []).includes(opt.name);
                    return (
                      <Box kind={'hflex'} gap='xs' align='center'>
                        {' '}
                        <input type='checkbox' checked={isChecked} /> <span>{opt.title}</span>
                      </Box>
                    );
                  }}
                  selectedValue={null}
                  // menuOpen
                  fetchOptions={async (txt) => {
                    if (!txt) return getPlugTypeOptions() || [];
                    const results = (getPlugTypeOptions() || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                    return results;
                  }}
                  onChanged={(selected) => {
                    if (!selected) return;
                    const allSelected = filterParams.plugTypes || [];
                    const exist = allSelected.includes(selected.name);
                    if (exist) {
                      const newSelected = allSelected.filter((item) => item !== selected.name);
                      setFilterParams({ plugTypes: newSelected.length > 0 ? newSelected : undefined });
                    } else {
                      setFilterParams({ plugTypes: [...allSelected, selected.name] });
                    }
                  }}
                  isClearable={true}
                />
                <ButtonSegments size='s'>
                  <ButtonSegment onClick={toggleFilter('ac')} toggled={!!filterParams.ac}>
                    {_t('AC')}
                  </ButtonSegment>
                  <ButtonSegment onClick={toggleFilter('dc')} toggled={!!filterParams.dc}>
                    {_t('DC')}
                  </ButtonSegment>
                </ButtonSegments>
                <ButtonSegments size='s'>
                  <ButtonSegment onClick={toggleFilter('public')} toggled={!!filterParams.public}>
                    {_t('Public Service')}
                  </ButtonSegment>
                </ButtonSegments>
                <Box kind={'hflex'} justify='flex-end' flexGrow='1'>
                  <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
                </Box>
              </Box>

              {filterSectionExpanded && (
                <Box kind={'hflex'} justify='flex-end'>
                  <ButtonToggles size='xs'>
                    <ButtonToggle toggled={!!filterParams.available} onClick={toggleFilter('available')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.available }}>
                      {_t('Available')}
                    </ButtonToggle>
                    <ButtonToggle toggled={!!filterParams.charging} onClick={toggleFilter('charging')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.charging }}>
                      {_t('Charging')}
                    </ButtonToggle>
                    <ButtonToggle toggled={!!filterParams.occupied} onClick={toggleFilter('occupied')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.occupied }}>
                      {_t('Occupied')}
                    </ButtonToggle>
                    <ButtonToggle toggled={!!filterParams.failure} onClick={toggleFilter('failure')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.failure }}>
                      {_t('Defective')}
                    </ButtonToggle>
                    <ButtonToggle toggled={!!filterParams.unavailable} onClick={toggleFilter('unavailable')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable }}>
                      {_t('Not available')}
                    </ButtonToggle>
                    <ButtonToggle toggled={!!filterParams.unknown} onClick={toggleFilter('unknown')} circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.unknown }}>
                      {_t('Unknown')}
                    </ButtonToggle>
                  </ButtonToggles>
                </Box>
              )}

              <ApiBasedContent
                resp={chargePointsResp}
                err={chargePointsErr}
                fetching={chargePointsFetching}
                placeholder={() => <StackedSkeleton skeleton={() => <HugeListItemSkeleton />} />}
              >
                {(chargePointsResp) => {
                  return (
                    <Box kind={'vflex'} gap='xl'>
                      <Box kind={'vflex'} gap='s'>
                        {chargePointsResp.chargePoints.map((cp, i) => {
                          const sessionsQuery = createQueryString({ entity: [{ id: cp.evseId, type: 'chargepoint' }] });
                          const sessionsLink = `/${chargePointsResp?.sessionsPagePrefix}-charging-sessions/${filterParams.clientCode}?${sessionsQuery}`;
                          const stationsPath = `/infrastructure/stations/${filterParams.clientCode}`;
                          const hasRemoteActions = remoteActions.hasChargePointRemoteActions(cp);
                          return (
                            <ChargepointListItem
                              key={i}
                              item={cp}
                              allNodes={[...(chargePointsResp?.clientContext.accessableNodes || []), ...(chargePointsResp?.clientContext.forbiddenParents || [])]}
                              sessionsLink={sessionsLink}
                              stationsPath={stationsPath}
                              clientCode={filterParams.clientCode}
                              loadManagementLinkActive={true}
                              hasContextIcons={hasRemoteActions}
                              onClickRemoteActionButton={(ev) => remoteActions.showChargePointRemoteActionContextMenu(ev, cp)}
                              hasChangeCustomNamePopup={cp.can.setCustomName}
                              onClickChangeCustomName={() => setCustomNameChangeChargePoint(cp)}
                            ></ChargepointListItem>
                          );
                        })}
                      </Box>
                      <Paging
                        className={classNames(styles.pagination, styles.tabContent)}
                        skip={chargePointsResp.skip}
                        limit={chargePointsResp.limit}
                        total={chargePointsResp.total}
                        onChange={(arg) => {
                          setFilterParams({
                            skip: arg.skip <= 0 ? null : arg.skip,
                            limit: arg.limit,
                          });
                        }}
                      />
                    </Box>
                  );
                }}
              </ApiBasedContent>
            </Box>
          </TabContent>
        </TabPanel>
      </>

      <StationCustomNamePopup
        close={() => setCustomNameChangeChargePoint(undefined)}
        onSubmit={async (customName) => {
          if (customNameChangeChargePoint) {
            api.station.setCustomNameForChargepoint({
              chargeBoxId: customNameChangeChargePoint.chargeBoxId,
              connectorId: customNameChangeChargePoint.connectorId,
              customName: customName,
              clientCode: filterParams.clientCode ?? '',
              connectorEvseId: customNameChangeChargePoint.evseId,
            });

            customNameChangeChargePoint.customName = customName;
          }

          setCustomNameChangeChargePoint(undefined);
        }}
        open={!!customNameChangeChargePoint}
        customName={customNameChangeChargePoint?.customName || ''}
        title={customNamePopupTitle}
      />

      {chargePointsResp?.can?.createSupportTicket && <CreateServiceTicketAction caller={{ callerPath: pathname, callerTitle: _t('Charging Infrastructure') }} />}
    </PageLayout>
  );
}
