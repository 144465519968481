import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowFirstIco } from '../../assets/arrow-first.svg';
import { ReactComponent as ArrowLastIco } from '../../assets/arrow-last.svg';
import { ReactComponent as DownloadIco } from '../../assets/download.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import { EnergyHistoryStackBarChart, EnergyStackBarChart, EnergyTotalsList, SessionsTotalsList } from '../../components-v2/business-components/common-charts/common-charts';
import { rateTypeColor } from '../../components-v2/business-components/domain-mappings';
import { SmartSuggest } from '../../components-v2/business-components/suggestions/suggestions';
import { ButtonSegment, ButtonSegments, ButtonToggle, ButtonToggles, ButtonV2Primary } from '../../components-v2/button';
import DataTableV2, { DataTableLayout } from '../../components-v2/data-table/data-table';
import { DateRangeChooser } from '../../components-v2/date-range';
import FilterToggle from '../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../components-v2/ico';
import { FormContainerV2 } from '../../components-v2/input';
import NumberRangeInput from '../../components-v2/number-range/number-range';
import { SelectedTag, SelectedTagArea, TagSuggestion, useTagSuggestion } from '../../components-v2/tag-suggestion/tag-suggestion';
import Box, { ChartSkeleton, Divider, HugeListItemSkeleton, StackedSkeleton } from '../../components-v2/utils';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { ApiBasedContent, PageLayout } from '../../components/page-layout';
import Paging from '../../components/paging';
import { rateTypeTranslation } from '../../components/tag';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import {
  AggregationLabels,
  ApiError,
  ChargingSessionsEntityFilterDto,
  ExportCardColumn,
  ExportFileType,
  ExportSessionColumn,
  ExportStationColumn,
  GetSessionsEnergyConsumptionResultDto,
  HttpResponse,
  RateServiceType,
  RateServiceTypeLabel,
  SessionStatus,
  SharedClientContext,
} from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { formateByResolution } from '../../utils/date';
import { formatDuration, formatKWhEnergy } from '../../utils/format';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, pagingValidator, validateArrayOfString, validatePositiveFloat, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import ExportSessionsPopup from './charging-sessions-export-popup';
import styles from './charging-sessions-list-page.module.scss';

export type FilterParameters = {
  from?: string;
  to?: string;
  skip?: number | null;
  limit?: number | null;
  serviceType?: string[];
  energyFrom?: number;
  energyTo?: number;
  purchaseCostFrom?: number;
  purchaseCostTo?: number;
  totalCostFrom?: number;
  totalCostTo?: number;
  status?: string[];
  entity?: ChargingSessionsEntityFilterDto[];
  sortBy?: string;
  sortDesc?: string;
  clientCode?: string;
  nodes?: string[];
  public?: string;
};

export interface ChargingSessionItem {}
export interface NotFmChargingSessionItem {
  stationIsExternalHardware?: boolean;
  stationIsPublic?: boolean;
}

export interface ListChargingSessionsResult<T extends ChargingSessionItem> {
  skip: number;
  limit: number;
  sessions: T[];

  entityFilter: ChargingSessionsEntityFilterDto[];
  clientContext: SharedClientContext;
  allowedServiceTypes: RateServiceType[];
  canAggregate: boolean;
}

export interface ChargingSessionSumsResult {
  total: number;
}

export interface ExportSessionsConfig {
  showStations: boolean;
  showCards: boolean;
}

export interface ExportSessionsArgs {
  sessionFields: ExportSessionColumn[];
  stationFields: ExportStationColumn[];
  cardFields: ExportCardColumn[];
  fileType: ExportFileType;
  aggregateByStationLocation: boolean;
  aggregateByCardLocation: boolean;
  aggregateByStationId: boolean;
  aggregateByCardId: boolean;
  serviceTypeLabels: RateServiceTypeLabel[];
  aggregationLabels: AggregationLabels;
  timezone: string;
}

export function SessionTimeCell(props: { startTime?: string; endTime?: string }) {
  const { user } = useAuth();

  return (
    <Box gap='xs' kind={'vflex'}>
      <Box kind={'hflex'} align='center' gap='xxs' fw='700'>
        <Ico size='10px' fill='primary-500' file={<ArrowFirstIco />} />
        {formatter.formatDateTimeFromIsoString(props.startTime, user?.preferences.languageCode, { dateStyle: 'short', timeStyle: 'short' })}
      </Box>
      <Box kind={'hflex'} align='center' gap='xxs' fw='700'>
        <Ico size='10px' fill='primary-500' file={<ArrowLastIco />} />
        {formatter.formatDateTimeFromIsoString(props.endTime, user?.preferences.languageCode, { dateStyle: 'short', timeStyle: 'short' })}
      </Box>
      <Box>{formatDuration(props.startTime, props.endTime)}</Box>
    </Box>
  );
}

export function StatusSessionColumn(args: { status: SessionStatus; dataCy?: string }) {
  const { _t } = useBetterTranslate('session-status-column-value');

  return (
    <>
      {args.status && args.status === SessionStatus.Charging && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.active)}>
          <span>{_t('Laden')}</span>
        </div>
      )}
      {args.status && args.status === SessionStatus.Occupied && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.occupied)}>
          <span>{_t('Besetzt')}</span>
        </div>
      )}
      {args.status && args.status === SessionStatus.Closed && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.closed)}>
          <span>{_t('Beendet')}</span>
        </div>
      )}
    </>
  );
}

export function ChargingSessionsListPage<
  Session extends ChargingSessionItem,
  SessionsApiReturn extends ListChargingSessionsResult<Session>,
  SumsApiReturn extends ChargingSessionSumsResult
>(props: {
  pagePrefix: string;
  sortValues: string[];
  totalCostFilter: boolean;
  purchaseCostFilter: boolean;
  isStationPublicFilter: boolean;
  serviceTypes: RateServiceType[];
  sessionsApiCall: (arg: FilterParameters) => Promise<HttpResponse<SessionsApiReturn, ApiError> | undefined>;
  sumsApiCall: (arg: FilterParameters) => Promise<HttpResponse<SumsApiReturn, ApiError> | undefined>;
  energyApiCall: (clientCode?: string, from?: string, to?: string, nodes?: string[]) => Promise<HttpResponse<GetSessionsEnergyConsumptionResultDto, ApiError> | undefined>;
  searchEntityTags: (clientCode: string | undefined, txt: string) => Promise<ChargingSessionsEntityFilterDto[]>;
  exportFieldsConfig: ExportSessionsConfig;
  exportApiCall: (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => Promise<HttpResponse<Blob, ApiError> | undefined>;
  tableLayout?: (
    sessionsResp: SessionsApiReturn,
    filterParams: FilterParameters,
    setFilterParams: (val: FilterParameters) => void,
    sumResp?: SumsApiReturn
  ) => DataTableLayout<Session>;
}) {
  const { _t } = useBetterTranslate('charging-sessions-list-page');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode || 'de';
  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SORT_BY_VALUES = ['energy', 'startTime', 'rateServiceType', ...props.sortValues];

  const navigate = useBetterNavigate<FilterParameters>();
  const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => props.searchEntityTags(sessionsResp?.clientContext?.code, val),
    createOption: (val) => <SmartSuggest label={val.title || ''} type={val.type} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => <SelectedTag onRemove={onRemove}>{val.title}</SelectedTag>,
  });

  const sessionsValidators = {
    serviceTypes: validateArrayOfString(props.serviceTypes),
    sortBy: validateString(SORT_BY_VALUES),
    energyFrom: validatePositiveFloat,
    energyTo: validatePositiveFloat,
    status: validateArrayOfString(Object.values(SessionStatus)),
  };
  let costValidators = {};
  if (props.totalCostFilter) {
    costValidators = { ...costValidators, totalCostFrom: validatePositiveFloat, totalCostTo: validatePositiveFloat };
  }
  if (props.purchaseCostFilter) {
    costValidators = { ...costValidators, purchaseCostFrom: validatePositiveFloat, purchaseCostTo: validatePositiveFloat };
  }

  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...sessionsValidators, ...costValidators };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const setFilterParams = useCallback(
    (filter: FilterParameters) => {
      const { skip, ...params } = filter;
      _setInnerFilterParams({ ...params, skip });
    },
    [_setInnerFilterParams]
  );

  const entityFilters = suggestion.selectedItems;
  useEffect(() => {
    setFilterParams({ entity: entityFilters });
  }, [entityFilters, setFilterParams]);

  const toggleFilter = (key: keyof FilterParameters) => {
    return () => {
      setFilterParams({ [key]: filterParams[key] ? undefined : '1' });
    };
  };
  const toggleStatusFilter = (status: SessionStatus) => {
    let allActive = [...(filterParams.status || [])];
    if (allActive.includes(status)) {
      allActive = allActive.filter((item) => item !== status);
    } else {
      allActive.push(status);
    }

    if (allActive.length <= 0) setFilterParams({ status: undefined });
    else setFilterParams({ status: allActive });
  };
  const toggleServiceFilter = (status: RateServiceType) => {
    let allActive = [...(filterParams.serviceType || [])];
    if (allActive.includes(status)) {
      allActive = allActive.filter((item) => item !== status);
    } else {
      allActive.push(status);
    }

    if (allActive.length <= 0) setFilterParams({ serviceType: undefined });
    else setFilterParams({ serviceType: allActive });
  };

  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const [showExportPopup, setShowExportPopup] = useState<boolean>(false);

  const [sessionsResp, sessionsRespFetching, sessionsRespError] = useApi(
    {
      call: async (filterParams: FilterParameters) => {
        console.log('load data');
        const result = await props.sessionsApiCall(filterParams);
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams
  );

  const [sumsResp, sumsFetching] = useApi(
    {
      call: async (filterParams: FilterParameters) => {
        const result = await props.sumsApiCall(filterParams);
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams
  );

  const [energyResp, energyRespFetching, energyRespErr] = useApi(
    {
      call: async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
        const result = await props.energyApiCall(clientCode, from, to, nodes);
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.from,
    filterParams.to,
    filterParams.nodes
  );

  const exportData = async (args: ExportSessionsArgs, cancelToken: string) => {
    if (!props.exportApiCall) {
      return;
    }

    const resp = await props.exportApiCall(filterParams, args, cancelToken);
    if (!resp) {
      return;
    }

    let filename = 'charging-sessions.xlsx';
    const disposition = resp.headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(resp.data);
    link.download = filename;
    link.click();
  };

  const sessionStatusFilterName = (tagType: SessionStatus) => {
    if (tagType === SessionStatus.Closed) return _t('Beendet');
    if (tagType === SessionStatus.Occupied) return _t('Besetzt');
    if (tagType === SessionStatus.Charging) return _t('Laden');
    return '';
  };

  return (
    <PageLayout className={styles.root}>
      <OrgHierarchyPicker
        selectMode='node'
        clientContext={sessionsResp?.clientContext}
        onNodeSelected={(clientCode, selectedCodes) => {
          if (clientCode !== sessionsResp?.clientContext?.code) {
            navigate(`/${props.pagePrefix}-charging-sessions/${clientCode}`, { nodes: selectedCodes });
          } else {
            setFilterParams({ nodes: selectedCodes });
          }
        }}
        {...createClientSearchProps(sessionsResp?.clientContext)}
        selectedNodes={filterParams.nodes}
      />

      <ApiBasedContent
        resp={energyResp}
        err={energyRespErr}
        fetching={energyRespFetching}
        placeholder={() => (
          <Box kind='hflex' gap='m'>
            <ChartSkeleton />
            <ChartSkeleton />
          </Box>
        )}
      >
        {(energyResp) => {
          let xLabel = '';
          if (energyResp.resolution && energyResp.entries && energyResp.entries.length > 1) {
            const start = formateByResolution(energyResp.entries[0].date, languageCode, energyResp.resolution);
            const end = formateByResolution(energyResp.entries[energyResp.entries.length - 1].date, languageCode, energyResp.resolution);
            xLabel = `${start} - ${end}`;
          } else if (energyResp.resolution && energyResp.entries && energyResp.entries.length === 1) {
            xLabel = formateByResolution(energyResp.entries[0].date, languageCode, energyResp.resolution);
          }

          return (
            <Box className={styles.charts} kind={'hgrid'} gap='m'>
              <Box kind='vflex' gap='m' cellSize='5'>
                {/* <Divider kind='h' /> */}
                <Box kind={'hflex'} gap='m'>
                  <Box flexGrow='1' kind={'vflex'}>
                    <SessionsTotalsList
                      customNodeTop={
                        <Box kind={'vflex'} gap='s'>
                          <Box fw='400' kind={'hflex'} justify='space-between'>
                            <Box>{_t('Total')}</Box>
                            <Box>{energyResp.count.totalCount}</Box>
                          </Box>
                          <Divider kind='h' />
                          <Box fw='400'>{_t('Used services')}</Box>
                        </Box>
                      }
                      // total={{ value: energyResp.count.totalCount }}
                      work={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.WORK)
                          ? undefined
                          : { value: energyResp.count.workCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.WORK] }) }
                      }
                      home={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.HOME)
                          ? undefined
                          : { value: energyResp.count.homeCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.HOME] }) }
                      }
                      employee={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE)
                          ? undefined
                          : { value: energyResp.count.employeeCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.EMPLOYEE] }) }
                      }
                      public={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC)
                          ? undefined
                          : { value: energyResp.count.publicCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.PUBLIC] }) }
                      }
                      unknown={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED)
                          ? undefined
                          : { value: energyResp.count.undefinedCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.UNDEFINED] }) }
                      }
                    />
                  </Box>
                  <Divider key={'v'} />
                  <Box flexGrow='1'>
                    <EnergyTotalsList
                      customNodeTop={
                        <Box kind={'vflex'} gap='s'>
                          <Box fw='400' kind={'hflex'} justify='space-between'>
                            <Box>{_t('Total')}</Box>
                            <Box>{`${formatKWhEnergy(energyResp.sum.totalEnergy.value, languageCode, 0, 0)}`}</Box>
                          </Box>
                          <Divider kind='h' />
                          <Box fw='400'>{_t('Used services')}</Box>
                        </Box>
                      }
                      // total={{ value: energyResp.sum.totalEnergy.value }}
                      work={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.WORK)
                          ? undefined
                          : { ...energyResp.sum.workEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.WORK] }) }
                      }
                      home={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.HOME)
                          ? undefined
                          : { ...energyResp.sum.homeEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.HOME] }) }
                      }
                      employee={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE)
                          ? undefined
                          : { ...energyResp.sum.employeeEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.EMPLOYEE] }) }
                      }
                      public={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC)
                          ? undefined
                          : { ...energyResp.sum.publicEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.PUBLIC] }) }
                      }
                      unknown={
                        !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED)
                          ? undefined
                          : { ...energyResp.sum.undefinedEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.UNDEFINED] }) }
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box kind={'vflex'} cellSize='7' gap='m'>
                <Box kind={'hflex'} justify='flex-end'>
                  <DateRangeChooser
                    minDate={MIN_DATE.toDate()}
                    maxDate={MAX_DATE.toDate()}
                    range={filterParams.from && filterParams.to ? { startDate: filterParams.from, endDate: filterParams.to } : undefined}
                    onChange={(range) => {
                      if (range) setFilterParams({ from: range.startDate, to: range.endDate });
                    }}
                  />
                </Box>

                <EnergyStackBarChart
                  work={!energyResp.allowedServiceTypes.includes(RateServiceType.WORK) ? undefined : energyResp.sum.workEnergy.percentage}
                  home={!energyResp.allowedServiceTypes.includes(RateServiceType.HOME) ? undefined : energyResp.sum.homeEnergy.percentage}
                  employee={!energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE) ? undefined : energyResp.sum.employeeEnergy.percentage}
                  public={!energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC) ? undefined : energyResp.sum.publicEnergy.percentage}
                  unknown={!energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED) ? undefined : energyResp.sum.undefinedEnergy.percentage}
                />

                <EnergyHistoryStackBarChart
                  resolution={energyResp.resolution}
                  xAxisLbl={xLabel}
                  work={!energyResp.allowedServiceTypes.includes(RateServiceType.WORK) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.workEnergy }))}
                  home={!energyResp.allowedServiceTypes.includes(RateServiceType.HOME) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.homeEnergy }))}
                  employee={!energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.employeeEnergy }))}
                  public={!energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.publicEnergy }))}
                  unknown={
                    !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.undefinedEnergy }))
                  }
                />
              </Box>
            </Box>
          );
        }}
      </ApiBasedContent>

      <ApiBasedContent
        err={sessionsRespError}
        fetching={sessionsRespFetching}
        resp={sessionsResp}
        placeholder={() => <StackedSkeleton skeleton={() => <HugeListItemSkeleton />} />}
      >
        {(sessionsResp) => {
          return (
            <Box kind={'vflex'} gap='m'>
              {/* as agreed w. florian, we don't show this on this page */}
              {/* {(hasExternalHardware || hasPublicStations) && <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />} */}

              <Box kind={'vflex'} gap='m' className={styles.tableContainer}>
                <Box kind={'vflex'} gap='m' pad={'500'}>
                  <Box kind={'hflex'} align='center' gap='m'>
                    <FormContainerV2>
                      <TagSuggestion {...suggestion.suggestorProps} />
                    </FormContainerV2>

                    <FormContainerV2>
                      <NumberRangeInput
                        placeHolder={_t('Geladene Energie')}
                        dataCy='charged_filter'
                        unit='kWh'
                        onChanged={({ from, to }) => setFilterParams({ energyFrom: from, energyTo: to })}
                        from={filterParams.energyFrom}
                        to={filterParams.energyTo}
                        maxFrom={1000}
                        maxTo={1000}
                        valueMaxFactionalDigits={0}
                        placeHolderMaxFactionalDigits={0}
                        commitBtnTxt={_t('Ergebnisse anzeigen')}
                      />
                    </FormContainerV2>

                    {props.purchaseCostFilter && (
                      <FormContainerV2>
                        <NumberRangeInput
                          placeHolder={_t('Ladeerstattung')}
                          dataCy='refund_filter'
                          unit='€'
                          onChanged={({ from, to }) => setFilterParams({ purchaseCostFrom: from, purchaseCostTo: to })}
                          from={filterParams.purchaseCostFrom}
                          to={filterParams.purchaseCostTo}
                          maxFrom={1000}
                          maxTo={1000}
                          valueMaxFactionalDigits={0}
                          placeHolderMaxFactionalDigits={0}
                          commitBtnTxt={_t('Ergebnisse anzeigen')}
                        />
                      </FormContainerV2>
                    )}

                    {props.totalCostFilter && (
                      <FormContainerV2>
                        <NumberRangeInput
                          placeHolder={_t('Ladekosten')}
                          dataCy='cost_filter'
                          unit='€'
                          onChanged={({ from, to }) => setFilterParams({ totalCostFrom: from, totalCostTo: to })}
                          from={filterParams.totalCostFrom}
                          to={filterParams.totalCostTo}
                          maxFrom={1000}
                          maxTo={1000}
                          valueMaxFactionalDigits={0}
                          placeHolderMaxFactionalDigits={0}
                          commitBtnTxt={_t('Ergebnisse anzeigen')}
                        />
                      </FormContainerV2>
                    )}

                    {props.isStationPublicFilter && (
                      <ButtonSegments size='s'>
                        <ButtonSegment onClick={toggleFilter('public')} toggled={!!filterParams.public}>
                          {_t('Public Service')}
                        </ButtonSegment>
                      </ButtonSegments>
                    )}

                    <Box kind={'hflex'} justify='flex-end' flexGrow='1' gap='m'>
                      <ButtonV2Primary size='s' dataCy='export' disabled={!sessionsResp || !sumsResp || sumsResp.total === 0} onClick={() => setShowExportPopup(true)}>
                        <Box kind={'hflex'} gap='xs' align='center' fw='700'>
                          <Ico fill={'brand-neutral-000'} size='12px' file={<DownloadIco />} />
                          {_t('Exportieren')}
                        </Box>
                      </ButtonV2Primary>
                      <Divider kind='v' />
                      <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
                    </Box>
                  </Box>

                  {filterSectionExpanded && (
                    <Box kind={'vflex'} gap='m'>
                      <Box kind={'hflex'} justify='flex-end' gap='m'>
                        <ButtonToggles size='xs'>
                          <ButtonToggle
                            dataCy={`pills_${SessionStatus.Charging}`}
                            toggled={filterParams.status?.includes(SessionStatus.Charging)}
                            onClick={() => toggleStatusFilter(SessionStatus.Charging)}
                            circle={{ color: 'blue-blue-jeans' }}
                          >
                            {sessionStatusFilterName(SessionStatus.Charging)}
                          </ButtonToggle>

                          <ButtonToggle
                            dataCy={`pills_${SessionStatus.Occupied}`}
                            toggled={filterParams.status?.includes(SessionStatus.Occupied)}
                            onClick={() => toggleStatusFilter(SessionStatus.Occupied)}
                            circle={{ color: 'red-light-salmon' }}
                          >
                            {sessionStatusFilterName(SessionStatus.Occupied)}
                          </ButtonToggle>

                          <ButtonToggle
                            dataCy={`pills_${SessionStatus.Closed}`}
                            toggled={filterParams.status?.includes(SessionStatus.Closed)}
                            onClick={() => toggleStatusFilter(SessionStatus.Closed)}
                            circle={{ color: 'brand-neutral-300' }}
                          >
                            {sessionStatusFilterName(SessionStatus.Closed)}
                          </ButtonToggle>
                        </ButtonToggles>
                        <Divider kind='v' />

                        {sessionsResp.allowedServiceTypes.length > 0 && (
                          <ButtonToggles size='xs'>
                            {sessionsResp.allowedServiceTypes.map((enumValue) => {
                              return (
                                <ButtonToggle
                                  dataCy={`pills_${enumValue}`}
                                  key={enumValue}
                                  toggled={filterParams.serviceType?.includes(enumValue)}
                                  onClick={() => toggleServiceFilter(enumValue)}
                                  circle={{ color: rateTypeColor(enumValue) }}
                                >
                                  {rateTypeTranslation(enumValue)}
                                </ButtonToggle>
                              );
                            })}
                          </ButtonToggles>
                        )}
                      </Box>

                      <SelectedTagArea {...suggestion.selectedTagAreaProps} />
                    </Box>
                  )}
                </Box>

                <DataTableV2
                  records={sessionsResp.sessions}
                  layout={props.tableLayout?.(sessionsResp, filterParams, setFilterParams, sumsResp)}
                  // layout={[
                  //   {
                  //     width: '3fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('EVSE-ID')}</Box>
                  //           <Box>{_t('Location')}</Box>
                  //           <Box>{_t('Area')}</Box>
                  //         </Box>
                  //       ),
                  //     },
                  //     cell: {
                  //       node: (r) => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='600'>DE*TEM*E123450*1</Box>
                  //           <Box ellipses >Korbach - Continental Reifen Deutschland GmbH</Box>
                  //           <Box >Default Area</Box>
                  //         </Box>
                  //       )
                  //     }
                  //   },

                  //   {
                  //     width: '3fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Badge Number')}</Box>
                  //           <Box>{_t('Badge Label')}</Box>
                  //           <Box>{_t('Cost Center')}</Box>
                  //         </Box>
                  //       ),
                  //     }
                  //   },

                  //   {
                  //     width: '3fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Start')}</Box>
                  //           <Box>{_t('End')}</Box>
                  //           <Box>{_t('Duration')}</Box>
                  //         </Box>
                  //       ),
                  //     }
                  //   },

                  //   {
                  //     width: '2fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Used')}</Box>
                  //           <Box fw='800'>{_t('Service')}</Box>
                  //         </Box>
                  //       ),
                  //     }
                  //   },

                  //   {
                  //     width: '2fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Charged')}</Box>
                  //           <Box fw='800'>{_t('Energy')}</Box>
                  //           <Box>{_t('(kWh)')}</Box>
                  //         </Box>
                  //       ),
                  //     },
                  //     subHeader: {
                  //       node: () => <Box fw='700'>19.0000</Box>
                  //     }
                  //   },

                  //   {
                  //     width: '2fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Refund')}</Box>
                  //           <Box>{_t('(€)')}</Box>
                  //         </Box>
                  //       ),
                  //     },
                  //     subHeader: {
                  //       node: () => <Box fw='700'>1.307</Box>
                  //     }
                  //   },

                  //   {
                  //     width: '2fr',
                  //     header: {
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Cost')}</Box>
                  //           <Box>{_t('(€)')}</Box>
                  //         </Box>
                  //       ),
                  //     },
                  //     subHeader: {
                  //       // span: 2,
                  //       node: () => <Box fw='700'>1.230</Box>
                  //     }
                  //   },

                  //   {
                  //     width: '2fr',
                  //     header: {
                  //       // padding: '0',
                  //       node: () => (
                  //         <Box kind={'vflex'}>
                  //           <Box fw='800'>{_t('Status')}</Box>
                  //         </Box>
                  //       ),
                  //     },
                  //     // subHeader: { padding: '0' }
                  //   },
                  // ]}
                />
              </Box>
            </Box>
          );
        }}
      </ApiBasedContent>

      {sessionsResp &&
        sumsResp &&
        (sumsFetching ? (
          <SpinnerIco />
        ) : (
          <Paging
            skip={sessionsResp.skip}
            limit={sessionsResp.limit}
            total={sumsFetching ? -1 : sumsResp.total}
            onChange={(arg) => {
              setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
            }}
          />
        ))}

      <ExportSessionsPopup
        showStations={props.exportFieldsConfig.showStations}
        showCards={props.exportFieldsConfig.showCards}
        canAggregate={sessionsResp?.canAggregate || false}
        open={showExportPopup}
        sessionsCount={sumsResp?.total || 0}
        close={() => setShowExportPopup(false)}
        onSubmit={async (args: ExportSessionsArgs, cancelToken: string) => await exportData(args, cancelToken)}
      />
    </PageLayout>
  );
}
