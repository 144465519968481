import moment from 'moment';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ReactComponent as ChartIco } from '../../assets/bar-chart.svg';
import { ReactComponent as CheckIco } from '../../assets/check.svg';
import Accordion, { AccordionContent, AccordionExpandBtn, AccordionHead } from '../../components-v2/accordion';
import DonutChart from '../../components-v2/donut-chart/donut-chart';
import Pill from '../../components-v2/pill';
import { SwitchToggle } from '../../components-v2/switch-toggle';
import TabPanel, { Tab, TabContent, Tabs } from '../../components-v2/tab-panel';
import Box, { AccordionSkeleton, ChartSkeleton, Divider, StackedSkeleton } from '../../components-v2/utils';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { ApiBasedContent, PageLayout } from '../../components/page-layout';

// import SessionsEnergyChart from '../../components-v2/business-components/sessions-energy-chart/sessions-energy-chart';
import { EnergyHistoryStackBarChart, EnergyStackBarChart, EnergyTotalsList } from '../../components-v2/business-components/common-charts/common-charts';
import { DateRangeChooser } from '../../components-v2/date-range';
import { chartTimePeriodLabel } from '../../components-v2/stacked-bar-chart/stacked-bar-chart';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { SessionsEnergyEntryDto, SessionsEnergySumDto, TimeGroupResolution } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatKWhEnergy, formatPercentage } from '../../utils/format';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './charging-stations-energy-page.module.scss';

interface ChildModelItem {
  nodeCode: string;
  expanded: boolean;
  payload?: {
    nodeTitle: string;
    entries: SessionsEnergyEntryDto[];
    sum: SessionsEnergySumDto;
    resolution: TimeGroupResolution;
  };
}
type FilterParameters = {
  from?: string;
  to?: string;
  skip?: number | null;
  limit?: number | null;
  clientCode?: string;
  nodes?: string[];
};

export default function ChargingStationsEnergyPage() {
  const { _t } = useBetterTranslate('charging-stations-energy-page');

  const MAX_DATE = useMemo(() => moment().startOf('d').add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().startOf('d').subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';

  const navigate = useBetterNavigate();

  const { user } = useAuth();
  const sessionUrlPrefix = user?.preferences.views.sessions.prefix;

  const [childrenModels, setChildrenModels] = useState<ChildModelItem[]>([]);

  const validators = { ...pagingValidator, ...hierarchyValidator, ...dateRangeValidator(MIN_DATE, MAX_DATE) };
  const [filterParams, setFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const fetchChildrenModels = async (code: string, areaCodes: string[], timezone: string, from?: string, to?: string) => {
    const promises = areaCodes.map((c) => {
      return api.stationsEnergy.getEnergy({ client: code, hierarchyNodeCodes: [c], from, to, timezone });
    });

    const responses = await Promise.all(promises);
    const results = responses.map((r) => r.data);
    const childrenModels = results.map((r): ChildModelItem => {
      return {
        nodeCode: r.clientContext.selectedNodes[0].code,
        expanded: false,
        payload: {
          nodeTitle: r.clientContext.selectedNodes[0].title,
          entries: r.entries,
          sum: r.sum,
          resolution: r.resolution,
        },
      };
    });

    setChildrenModels(childrenModels);
  };

  const navigateToChargingSessionsPage = (filter: { serviceType?: string[]; nodes?: string[] }) => {
    const link = `/${sessionUrlPrefix}-charging-sessions/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const onToggleExpand = (childModel: ChildModelItem) => {
    childModel.expanded = !childModel.expanded;
    setChildrenModels([...childrenModels]);
  };

  const onToggleFoldAll = () => {
    const expandAll = childrenModels.filter((child) => child.expanded === false).length > 0;
    for (let child of childrenModels) {
      child.expanded = expandAll;
    }
    setChildrenModels([...childrenModels]);
  };

  const navigateToStatusPage = () => {
    navigate(`/locations/status/${filterParams.clientCode}`, { nodes: filterParams.nodes });
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[], fromDate?: string, toDate?: string) => {
        if (!clientCode) return undefined;
        const response = await api.stationsEnergy.getEnergy({
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
          from: startOfDay(fromDate),
          to: endOfDay(toDate),
          timezone: getTimezone(),
        });
        if (response.error) {
          return response;
        }
        const data = response.data;
        const nodes2Fetch = data.clientContext.selectedHierarchy.filter((c) => c.type === 'area').map((item) => item.code);
        const childrenItems = nodes2Fetch.map((item): ChildModelItem => ({ nodeCode: item, expanded: true, payload: undefined }));

        setChildrenModels(childrenItems);
        fetchChildrenModels(data.clientContext.code, nodes2Fetch, getTimezone(), startOfDay(fromDate), endOfDay(toDate));
        return response;
      },
      map: (data) => {
        if (!data) return data;
        return {
          clientContext: data.clientContext,
          entries: data.entries,
          sum: data.sum,
          resolution: data.resolution,
        };
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to
  );
  const anyPanelCollapsed = childrenModels.filter((p) => p.expanded === false).length > 0;

  return (
    <PageLayout className={styles.root}>
      <OrgHierarchyPicker
        selectMode='node'
        clientContext={mainModel?.clientContext}
        onNodeSelected={(clientCode, selectedCodes) => {
          if (clientCode !== mainModel?.clientContext?.code) {
            navigate(`/locations/energy/${clientCode}`, { nodes: selectedCodes });
          } else {
            setFilterParams({ nodes: selectedCodes });
          }
        }}
        {...createClientSearchProps(mainModel?.clientContext)}
        selectedNodes={filterParams.nodes}
      />
      <Box kind='vflex' gap='l'>
        <TabPanel>
          <Tabs>
            <Tab strokeIco txt={_t('Status')} ico={<ChartIco />} onClick={navigateToStatusPage}></Tab>
            <Tab fillIco active={true} txt={_t('Energie')} ico={<CheckIco />}></Tab>
          </Tabs>
          <TabContent active={true}>
            <ApiBasedContent
              resp={mainModel}
              err={errMainModel}
              fetching={fetchingMainModel}
              placeholder={() => (
                <Box kind='hflex' gap='m'>
                  <ChartSkeleton />
                  <ChartSkeleton />
                </Box>
              )}
            >
              {(mainModel) => {
                return (
                  <Box kind='vflex' gap='m'>
                    <Box kind={'hflex'} justify='flex-end'>
                      <DateRangeChooser
                        minDate={MIN_DATE.toDate()}
                        maxDate={MAX_DATE.toDate()}
                        range={filterParams.from && filterParams.to ? { startDate: filterParams.from, endDate: filterParams.to } : undefined}
                        onChange={(range) => {
                          if (range) setFilterParams({ from: range.startDate, to: range.endDate });
                        }}
                      />
                    </Box>
                    {/* <DateRangePicker
                      className={styles.datePicker}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      selected={filterParams}
                      onChange={(range) => setFilterParams({ ...range })}
                      dateFormat={'YYYY-MM-DD'}
                    /> */}
                    <EnergyChartPanel
                      entries={mainModel.entries}
                      sum={mainModel.sum}
                      resolution={mainModel.resolution}
                      totalOverall={mainModel.sum.totalEnergy.value}
                      onOverallClick={() => navigateToChargingSessionsPage({ nodes: filterParams.nodes })}
                      onWorkClick={() => navigateToChargingSessionsPage({ serviceType: ['WORK'], nodes: filterParams.nodes })}
                      onEmployeeClick={() => navigateToChargingSessionsPage({ serviceType: ['EMPLOYEE'], nodes: filterParams.nodes })}
                      onPublicClick={() => navigateToChargingSessionsPage({ serviceType: ['PUBLIC'], nodes: filterParams.nodes })}
                      onUnknownClick={() => navigateToChargingSessionsPage({ serviceType: ['UNDEFINED'], nodes: filterParams.nodes })}
                    />
                  </Box>
                );
              }}
            </ApiBasedContent>
          </TabContent>
        </TabPanel>
        <Divider kind='h' />
        <Box kind='hflex' justify='flex-end'>
          <button className={styles.unfoldAllBtn} type='button' onClick={onToggleFoldAll}>
            {anyPanelCollapsed ? _t('Alle Bereiche öffnen') : _t('Alle Bereiche schließen')}
            <SwitchToggle
              checked={!anyPanelCollapsed}
              onChange={() => {
                console.log('on change');
              }}
            />
          </button>
        </Box>

        <ApiBasedContent resp={mainModel} err={errMainModel} fetching={fetchingMainModel} placeholder={() => <StackedSkeleton skeleton={() => <AccordionSkeleton />} />}>
          {(mainModel) => {
            return (
              <Box kind='vflex' gap='l'>
                {childrenModels.map((childModel, idx) => {
                  return (
                    <ApiBasedContent resp={childModel.payload} key={idx} fetching={!childModel.payload} placeholder={() => <AccordionSkeleton />}>
                      {(payload) => {
                        return (
                          <Accordion collapsed={!childModel.expanded}>
                            <AccordionHead>
                              <Box kind='hgrid' gap='s' flexGrow='1' align='center'>
                                <Box cellSize='3' kind='hflex' align='center'>
                                  <NodeHierarchyLabelInverted
                                    hideArea={false}
                                    hideClientRoot={true}
                                    allNodes={[...mainModel.clientContext.accessableNodes, ...mainModel.clientContext.forbiddenParents]}
                                    code={childModel.nodeCode}
                                  />
                                </Box>
                                <Box kind='hgrid' cellSize='3' fs='s'>
                                  <Box kind='vflex'>
                                    <Pill apperance='dataviz-green-light-green' />
                                    <span>
                                      {formatKWhEnergy(Math.round(payload.sum.workEnergy.value), user?.preferences.languageCode)} (
                                      {formatPercentage(payload.sum.workEnergy.percentage, user?.preferences.languageCode, 0, 0)})
                                    </span>
                                  </Box>
                                  <Box kind='vflex'>
                                    <Pill apperance='green-kelly-green' />
                                    <span>
                                      {formatKWhEnergy(Math.round(payload.sum.employeeEnergy.value), user?.preferences.languageCode)} (
                                      {formatPercentage(payload.sum.employeeEnergy.percentage, user?.preferences.languageCode, 0, 0)})
                                    </span>
                                  </Box>

                                  <Box kind='vflex'>
                                    <Pill apperance='dataviz-blue-medium-persian' />
                                    <span>
                                      {formatKWhEnergy(Math.round(payload.sum.publicEnergy.value), user?.preferences.languageCode)} (
                                      {formatPercentage(payload.sum.publicEnergy.percentage, user?.preferences.languageCode, 0, 0)})
                                    </span>
                                  </Box>

                                  <Box kind='vflex'>
                                    <Pill apperance='functional-disabled-200' />
                                    <span>
                                      {formatKWhEnergy(Math.round(payload.sum.undefinedEnergy.value), user?.preferences.languageCode)} (
                                      {formatPercentage(payload.sum.undefinedEnergy.percentage, user?.preferences.languageCode, 0, 0)})
                                    </span>
                                  </Box>
                                </Box>
                                <Box cellSize='2' kind='hflex' h='100%' align='center'>
                                  <Divider kind='v' />
                                  <Box pad={['0', '0', '0', '300']}>{`${_t('Geladene Energie')}: ${formatKWhEnergy(
                                    payload.sum.totalEnergy.value,
                                    user?.preferences.languageCode
                                  )}`}</Box>
                                </Box>
                              </Box>

                              <Divider kind='v' />
                              <AccordionExpandBtn className={styles.panelBtn} onClick={() => onToggleExpand(childModel)} />
                            </AccordionHead>

                            <AccordionContent>
                              <EnergyChartPanel
                                entries={payload.entries}
                                sum={payload.sum}
                                resolution={payload.resolution}
                                totalOverall={mainModel.sum.totalEnergy.value}
                                onOverallClick={() => navigateToChargingSessionsPage({ nodes: [childModel.nodeCode] })}
                                onWorkClick={() => navigateToChargingSessionsPage({ serviceType: ['WORK'], nodes: [childModel.nodeCode] })}
                                onEmployeeClick={() => navigateToChargingSessionsPage({ serviceType: ['EMPLOYEE'], nodes: [childModel.nodeCode] })}
                                onPublicClick={() => navigateToChargingSessionsPage({ serviceType: ['PUBLIC'], nodes: [childModel.nodeCode] })}
                                onUnknownClick={() => navigateToChargingSessionsPage({ serviceType: ['UNDEFINED'], nodes: [childModel.nodeCode] })}
                              />
                            </AccordionContent>
                          </Accordion>
                        );
                      }}
                    </ApiBasedContent>
                  );
                })}
              </Box>
            );
          }}
        </ApiBasedContent>
      </Box>
    </PageLayout>
  );
}

function EnergyChartPanel(
  props: PropsWithChildren<{
    entries: SessionsEnergyEntryDto[];
    sum: SessionsEnergySumDto;
    resolution: TimeGroupResolution;
    totalOverall: number;
    onOverallClick?: () => void;
    onWorkClick?: () => void;
    onEmployeeClick?: () => void;
    onPublicClick?: () => void;
    onUnknownClick?: () => void;
  }>
) {
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const xAxixLbl = chartTimePeriodLabel(props.resolution, props.entries, languageCode || 'en');
  const resolution = props.resolution;

  return (
    <Box kind={['vflex', 'lg:hflex']} gap='l'>
      <Box cellSize='5' kind='hflex' gap='l'>
        <Box className={styles.donut}>
          <div>
            <div>
              <DonutChart
                counterClockWise={false}
                items={[
                  { color: 'atenso-blue', value: props.sum.totalEnergy.value },
                  { color: 'transparent', value: props.totalOverall - props.sum.totalEnergy.value },
                ]}
              >
                <Box kind='vflex' testId={'chargepoints_total'} gap='xs' className={styles.donutEnergyValue}>
                  <Box fs={['5xl']} lh='2xl' txtAlign='center' flexGrow='1'>
                    {formatter.formatNumber(props.sum.totalEnergy.value, languageCode)}
                  </Box>
                  <Box txtAlign='center' fs={['xl']} lh='2xl'>
                    kWh
                  </Box>
                </Box>
              </DonutChart>
            </div>
          </div>
        </Box>
        <Box flexGrow='1' gap='l' className={styles.legend}>
          <EnergyTotalsList
            total={{ value: props.sum.totalEnergy.value, onClick: props.onOverallClick }}
            work={{ ...props.sum.workEnergy, onClick: props.onWorkClick }}
            employee={{ ...props.sum.employeeEnergy, onClick: props.onEmployeeClick }}
            public={{ ...props.sum.publicEnergy, onClick: props.onPublicClick }}
            unknown={{ ...props.sum.undefinedEnergy, onClick: props.onUnknownClick }}
            // home={{val: props.sum.homeEnergy.value, onClick: props.on}}
          />
        </Box>
      </Box>
      <Box flexGrow='1' cellSize='7' kind='vflex' gap='l'>
        <EnergyStackBarChart
          employee={props.sum.employeeEnergy.percentage}
          public={props.sum.publicEnergy.percentage}
          home={props.sum.homeEnergy.percentage}
          unknown={props.sum.undefinedEnergy.percentage}
          work={props.sum.workEnergy.percentage}
        />

        <EnergyHistoryStackBarChart
          work={props.entries.map((r) => ({ xKey: r.date, y: r.workEnergy }))}
          employee={props.entries.map((r) => ({ xKey: r.date, y: r.employeeEnergy }))}
          public={props.entries.map((r) => ({ xKey: r.date, y: r.publicEnergy }))}
          unknown={props.entries.map((r) => ({ xKey: r.date, y: r.undefinedEnergy }))}
          xAxisLbl={xAxixLbl}
          resolution={resolution}
        />
      </Box>
    </Box>
  );
}
// const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.PUBLIC, RateServiceType.UNDEFINED];
