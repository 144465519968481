import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Circle } from '../ico';
import Box, { BoxProps, Colors } from '../utils';
import styles from './badge.module.scss';

export type BadgeProps = BoxProps & {};

export default function Badge(props: PropsWithChildren<BadgeProps>) {
  return (
    <Box {...props} className={classNames(styles.root, props.className)}>
      {props.children}
    </Box>
  );
}

export type DotBadgeProps = BoxProps & {
  circleColor?: Colors;
};
export function DotBadge(props: PropsWithChildren<DotBadgeProps>) {
  return (
    <Box kind={'hflex'} gap='s' align='center' {...props} className={classNames(styles.dotBadge, props.className)}>
      <Circle size='8px' color={props.circleColor} /> {props.children}
    </Box>
  );
}
